<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header class="pb-3">
        <h1>Modifier la page</h1>
      </header>

      <LocalSwitcher
        @updateCurrentLocale="updateCurrentLocale($event)"
      ></LocalSwitcher>

      <div>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="submit"
        >
          <p v-if="successMessage">
            <v-alert
              text
              dense
              color="teal"
              border="left"
              type="success"
            >
              {{ successMessage }}
            </v-alert>
          </p>

          <p v-if="errors.length">
            <v-alert
              text
              dense
              type="error"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error.title }}
            </v-alert>
          </p>

          <v-text-field
            class="mb-2"
            label="Title"
            v-model="title[currentLocale]"
            required
          ></v-text-field>

          <v-text-field
            class="mb-2"
            label="Slug"
            :value="entityData.slug"
            @input="checkChar"
            required
          ></v-text-field>

          <div class="tall_editor">
            <quill-editor
              ref="myQuillEditor"
              v-model="content[currentLocale]"
              :options="editorOption"
            />
          </div>

          <button type="submit" :disabled="!valid" class="btn btn-primary float-right mt-4">
            <i class="fas fa-check-circle"></i>Valider
          </button>

          <router-link
            to="/administration/page/"
            class="btn btn-danger float-right mt-4 mr-3 text-white"
          >
            <i class="fa fa-times"></i>
            <span>Cancel</span>
          </router-link>

        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import Vue from 'vue'
import LocalSwitcher from '@/components/LocalSwitcher.vue'

export default {
  name: 'Page',
  components: { LocalSwitcher, quillEditor },
  data: () => ({
    valid: false,
    successMessage: null,
    errors: [],
    loading: false,
    editorOption: {},
    currentLocale: 'fr',
    id: [],
    title: [],
    content: [],
    entityData: {
      id: null,
      slug: null,
      currentLocale: 'fr',
      defaultLocale: 'fr',
      newTranslations: {}
    }
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - Initialisation de la clé mâitre'

    if (this.$route.params.slug) {
      this.getPage(this.$route.params.slug)
    }
  },
  methods: {
    getPage: function (slug) {
      Vue.prototype.$http
        .get(config.apiUrl + '/pages/' + slug, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          const page = response.data
          const translations = page.translations

          for (const [currentKey] of Object.entries(translations)) {
            let currentTranslation = translations[currentKey]

            this.id[currentKey] = currentTranslation.id
            this.title[currentKey] = currentTranslation.title
            this.content[currentKey] = currentTranslation.content
          }

          this.entityData.id = page.id
          this.entityData.slug = page.slug
        })
        .catch(error => {
          console.log(error)
        })
    },
    submit: function () {
      if (this.valid) {
        this.loading = true

        let method = 'PUT'
        let slug = '/' + this.entityData.id

        if (!this.$route.params.slug) {
          method = 'POST'
          slug = ''
        }

        const translations = [
          'title',
          'content'
        ]

        if (method === 'PUT') {
          translations.push('id')
        }

        this.handleTranslations(translations)

        if (method === 'POST') {
          delete this.entityData.id
        }

        Vue.prototype.$http
          .post(config.apiUrl + '/pages' + slug, this.entityData, {
            headers: {
              Authorization: 'Bearer ' + this.$store.getters.getToken
            },
            params: {
              _method: method
            }
          })
          .then(response => {
            this.$router.push({ name: 'PageList' })
            this.loading = false
          })
          .catch(e => {
            const response = e.response

            this.loading = false

            if (response.status === 400 || response.status === 404) {
              this.hasMasterKey = false
            }

            if (response.status === 403) {
              this.$store.dispatch('logout', 'rejected')
            }
          })
      }
    },
    checkChar: function (e) {
      this.slug = e.replace(/[^a-zA-Z0-9]/g, '-')
    },
    handleTranslations (attributes) {
      for (let i = 0; i < attributes.length; i++) {
        const currentAttribute = attributes[i]

        for (const [currentKey, currentValue] of Object.entries(this[currentAttribute])) {
          let translation = {
            locale: currentKey,
            empty: false
          }

          translation[currentAttribute] = currentValue

          const currentObjState = this.entityData.newTranslations[currentKey]

          this.entityData.newTranslations[currentKey] = { ...currentObjState, ...translation }
        }
      }
    },
    updateCurrentLocale (locale) {
      this.currentLocale = locale
      this.successMessage = null
    }
  }
}
</script>

<style>
.tall_editor .ql-container {
  min-height: 500px;
  background: white;
}
</style>
